.slider-wrapper {
  margin-top: 3rem;
  padding: 2rem 1rem;
  background: url("../../../../public/media/green_jens.jpg") rgba(0, 0, 0, 0.5);
  background-size: cover;
  background-blend-mode: multiply;
}

.slide p {
  margin: 1rem 0;
}

.slider-nav {
  margin: 2rem 0 2rem 0;
  display: flex;
  gap: 4rem;
  transition: all 0.2s linear;
}

.is-active {
  font-weight: 700;
  font-size: 1.2rem;
  margin-top: -0.5rem;
}

.contact {
  display: block;
  text-align: center;
}
