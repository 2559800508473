.modalwrapper {
  position: absolute;
  inset: 0;
  height: 100 vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.modal-img {
  width: 80vw;
  filter: hue-rotate(270deg);
  margin-bottom: 2rem;
}

.modalwrapper span {
  position: absolute;
  right: 2rem;
  top: 2rem;
  color: white;
  font-weight: bold;
  font-size: 2rem;
}

.modalwrapper p {
  margin: 0 2rem;
}
