@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@300;700&display=swap");

body,
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app {
  width: 100vw;
  height: 100vh;
  background-color: #313131;
  font-family: "IBM Plex Mono", monospace;
  overflow: hidden;
}

h1,
h2,
h3,
p,
a {
  color: #07c803;
  text-decoration: none;
}
