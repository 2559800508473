.accordion-big-wrap {
  width: 100%;
}

.accordion-big-item {
  display: grid;
  padding: 1rem 0;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  border: 1px solid #07c803;
}

.accordion-big-item h2 {
  width: 100%;
  text-align: center;
  font-size: 2.5em;
  text-transform: uppercase;
}

.accordionNavlink {
  grid-area: 2 / 1 / 2 / -1;
  text-align: center;
  height: 0;
  opacity: 0;
  transition: all 0.1s linear;
}

.linkShows {
  grid-area: 2 / 1 / 2 / -1;
  text-align: left;
  height: auto;
  opacity: 1;
  margin: 1rem;
}
