.header {
  display: flex;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #07c803;
  color: #07c803;
  text-transform: uppercase;
}

.header .h2 {
  font-weight: 300;
  font-size: 1em;
}

.header .h1 {
  font-weight: 700;
  font-size: 1.5em;
}
