.footer {
  position: absolute;
  bottom: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.footer-menu-item {
  border: 2px solid #07c803;
  width: 25%;
  text-align: center;
  padding: 1rem 0;
}
